import { breakpoints, theme, ThemeOverrides } from "@shapeable/theme";
import { Banner, Color } from "@shapeable/types";
import { css } from "styled-components";

import BLUE_BG from "./images/blue-bg.png";
import BIEGE_BG from "./images/biege-bg.png";

export const ORANGE = '#F7921D';

export const MEDIUM_BLUE = '#2496BD';
export const DARK_BLUE = '#02394B';
export const CONTENT_BACKGROUND = '#FFF';

export const BLUE = '#029BC9';
export const BLACK = '#000000';
export const LIGHT_BROWN = '#AD9A8A';

export const BLUE_OVERLAY = `${BLUE}EE`;
export const BROWN_OVERLAY = `${LIGHT_BROWN}BB`;

export const DARK_GREEN = '#001709';

export const BRAND_1 = BLUE;
export const BRAND_2 = LIGHT_BROWN;
export const BRAND_3 = BLACK;

export const SESSION_BLUE = '#AEDCE3';
export const SESSION_ORANGE = '#FFA200';
export const SESSION_GREEN = '#C2E5CB';
export const SESSION_YELLOW = '#FFD088';


export const BRAND_COLORS = [BRAND_1, BRAND_2, BRAND_3];

export const LIGHT_BIEGE = '#F7F5F3';
export const VERY_LIGHT_BROWN = '#D5CDC4';
export const MEDIUM_BROWN = '#796c61';
export const DARK_BROWN = '#685c53';

export const EXTREMELY_LIGHT_BROWN = '#e6e1dc';

export const FOOTER_BACKGROUND = '#D5CDC4';


/* Canned entities to speed things up a bit */

export const BANNER_BLUE_BG: Banner = {
  "id": "banner-leaf",
  "name": "Blue BG",
  "slug": "blue-bg",
  "image": {
      "id": "blue-bg-img",
      "url": BLUE_BG,
      "width": 2540,
      "height": 521
  }
};


export const BANNER_BIEGE_BG: Banner = {
  "id": "banner-biege-bg",
  "name": "Biege BG",
  "slug": "biege-bg",
  "image": {
      "id": "biege-bg-img",
      "url": BIEGE_BG,
      "width": 1920,
      "height": 576
  }
};

export const COLOR_LIGHT_BROWN: Color = {
  id: 'light-brown',
  slug: 'light-brown',
  value: LIGHT_BROWN,
};

export const COLOR_BLUE: Color = {
  id: 'blue',
  slug: 'blue',
  value: BLUE,
};

export const themeOverrides: ThemeOverrides = {
  font: {
    family: {
      serif: "'Cormorant Garamond', georgia, serif",
      sans: 'Jost, sans-serif',
      heading: 'Jost, sans-serif',
      copy: 'Jost, sans-serif',
    }
  },
  light: {
    color: {
      primary: BLUE,
      secondary: LIGHT_BROWN,
  
      link: BLUE,
      linkHover: LIGHT_BROWN,
      
      heading: BLUE,

      button: BLUE,
      buttonHover: MEDIUM_BROWN,
    },
  },
};


export const FontSizeStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(17)};
  `
});


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    ${FontSizeStyles};

    .shp--site-header-layout__navigation {
      font-size: 1.2em;
    }

    .shp--site-menu-bar {
      background: linear-gradient(0deg, ${BLUE}99, ${BLUE}99), url(${BANNER_BLUE_BG.image.url});
      background-size: cover;
      opacity: 0.95;

      .shp--icon {
        ${theme.FILL('bright')}
      }
    }


    .shp--session-layout__presenter {
      margin-top: -5px;
    }

    .shp--slice-intro .shp--markdown-content {
      font-size: 1em;
      text-align: left;
      line-height: 1.35em;
      font-weight: 300;
      max-width: 900px;
    }

    .shp--organisation-powered-by {
      img {
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }
  `,
  tablet: css`
    .shp--slice-intro .shp--markdown-content {
      font-size: 1.5em;
    }
  `,
  desktop: css`

  `
}); 
