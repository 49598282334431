import { Banner, Color, Page, Slice } from "@shapeable/types";
import { COLOR_WHITE } from "@shapeable/ui";
import { COLOR_BLUE } from "./theme";

export const createIntroSlice: (entity: Page) => Slice = (entity) => ({
  id: 'intro-slice',
  intro: entity.intro,
  backgroundColor: COLOR_BLUE,
  color: COLOR_WHITE,
  layout: {
    id: 'slice-layout-intro',
    slug: 'slice-layout-intro',
    component: 'SliceLayoutIntro',
  }
});
